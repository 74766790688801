import React, {useState} from "react";
import BMSLogo from "../icons/BMSLogo";
import {verifyAndReturnMeetingID} from "../utils/helper";

export function MeetingDetailsScreen({onClickJoin}) {
    const queryParams = new URLSearchParams(window.location.search)
    const [meetingId, setMeetingId] = useState(verifyAndReturnMeetingID(queryParams.get("meet-id") ?? ''));
    const [meetingPassword, setMeetingPassword] = useState('');
    const [participantName, setParticipantName] = useState(queryParams.get("name") ?? '');
    const [userRole] = useState(queryParams.get("user-role") ?? '');
    const [joinButtonLabel] = useState(userRole === 'admin' ? 'Join Meeting' : 'Ask to join');
    const [meetingIdError, setMeetingIdError] = useState(false);

    return (
        <div
            className={`flex flex-1 flex-col justify-center w-full md:p-[6px] sm:p-1 p-1.5`}
        >
            <div className="mb-2">{BMSLogo} </div>
            <input
                value={meetingId}
                onChange={(e) => setMeetingId(e.target.value)}
                placeholder="Enter meeting ID"
                className="px-4 py-3 mt-5 bg-gray-650 rounded-xl text-white w-full text-center"
            />

            {meetingIdError && (
                <p className="text-xs text-red-300 mt-2">{`Please enter valid meetingId`}</p>
            )}

            <input
                value={participantName}
                onChange={(e) => setParticipantName(e.target.value)}
                placeholder="Enter your name"
                className="px-4 py-3 mt-5 bg-gray-650 rounded-xl text-white w-full text-center"
            />

            {userRole === 'admin' && (
                <input
                    value={meetingPassword}
                    onChange={(e) => setMeetingPassword(e.target.value)}
                    placeholder="Enter password"
                    className="px-4 py-3 mt-5 bg-gray-650 rounded-xl text-white w-full text-center"
                />
            )}

            <button
                disabled={(userRole === 'admin' && meetingPassword.length < 5) || participantName.length < 3}
                className={`w-full ${(userRole === 'admin' && meetingPassword.length < 5) || participantName.length < 3 ? "bg-gray-650" : "bg-purple-350"
                }  text-white px-2 py-3 rounded-xl mt-5`}
                onClick={(e) => {
                    if (meetingId.match("\\w{4}\\-\\w{4}\\-\\w{4}")) {
                        setMeetingIdError(false);
                        onClickJoin(meetingId, participantName, meetingPassword, userRole === 'admin');
                    } else setMeetingIdError(true);
                }}
            >
                {joinButtonLabel}
            </button>
        </div>
    );
}
