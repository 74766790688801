const API_VALIDATE_MEETING = "https://meet.bmsauditing.com/backend/Meet/validate_meeting";
const API_AUTHENTICATE_MEETING = "https://meet.bmsauditing.com/backend/Meet/authorize_admin_for_meeting";

export const validateMeetingAndJoin = async ({roomId}) => {

    const options = {
        method: "POST",
        body: JSON.stringify({
            meeting_id: roomId,
        })
    };

    const response = await fetch(API_VALIDATE_MEETING, options)

    if (response.status === 400) {
        const data = await response.text()
        return {meetingId: null, err: data}
    }

    const data = await response.json()

    if (data.status && data.token !== '') {
        return {meetingId: data.meeting_id, token: data.token, err: null}
    } else {
        return {meetingId: null, token: null, err: data.msg}
    }

};

export const validateMeetingAdminAndJoin = async ({roomId, password}) => {

    const options = {
        method: "POST",
        body: JSON.stringify({
            meeting_id: roomId,
            meeting_password: password,
        })
    };

    const response = await fetch(API_AUTHENTICATE_MEETING, options)

    if (response.status === 400) {
        const data = await response.text()
        return {meetingId: null, err: data}
    }

    const data = await response.json()

    if (data.status && data.token !== '') {
        return {meetingId: data.meeting_id, token: data.token, err: null}
    } else {
        return {meetingId: null, token: null, err: data.msg}
    }

};